<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-files"> </i> 档案管理 | 项目列表</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增项目</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态">
                <el-option label="正常" value="1" />
                <el-option label="归档" value="9" />
              </el-select>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px" >
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
              <el-table-column prop="NAME" label="项目名称" width="500" />
              <el-table-column prop="CNT_FILE" label="文件数" width="70" align="center"/>
              <el-table-column label="创建日期" width="100" align="center">
                <template slot-scope="scope">
                  {{scope.row.CREATED_DT | datetime('YYYY-MM-DD')}}
                </template>
              </el-table-column>
              <el-table-column prop="STA_TXT" label="状态" width="60" align="center" />
              <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-document" @click="toSpace(scope.row)" >项目空间</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)" v-if="scope.row.STA!=9">修改</el-button>
                  <el-button type="text" icon="el-icon-lock" @click="rowLock(scope.row)" v-if="scope.row.STA!=9" >归档</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)" v-if="scope.row.STA!=9">删除</el-button>
                </template>
              </el-table-column>
              <el-table-column />
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="archive_edit" @on-saved="filter" />
    </div>
  </div>
</template>
<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
import Swal from "sweetalert2";
export default {
  name: "index",
  components: {Wgrid, Edit},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
        },
        ls:[],
        total:0,
        loading:false
      },
    }
  },
  created() {
    this.getList();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/DOC/ArchiveApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
    rowEdit(r){
      this.$refs.archive_edit.init(r);
    },
    rowLock(r){
      let self=this;
      Swal.fire({
        text:"真的要归档这个项目吗，归档后不可再上传和修改文件？",
        icon:"question",
        confirmButtonText:"确认",
        cancelButtonText:"取消",
        showCancelButton:true,
      }).then((v)=>{
        if(v.isConfirmed) {
          self.whale.remote.getResult({
            url:"/api/School/DOC/ArchiveApi/Lock",
            data:{ID:r.ID},
            completed:function (){
              self.filter();
              self.whale.toast.info("项目归档成功")
            }
          })
        }
      })
    },
    rowDel(r){
      this.whale.delConfirm(()=>{
        let self=this;
        this.whale.remote.getResult({
          url:"/api/School/DOC/ArchiveApi/Delete",
          data:{ID:r.ID},
          completed:function (){
            //self.filter();
            self.grid.ls=self.grid.ls.filter(o=>o.ID!=r.ID)
          }
        })
      });
    },
    toSpace(r){
      this.$router.push({name:'doc-content',params:r})
    }
  }
}
</script>

